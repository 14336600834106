import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 68px 0;
  text-align: center;
`

export const SectionTitle = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 12px;

  font-size: 22px;
  font-family: ${({ theme }) => theme.font.styles.bold};
  color: ${({ theme }) => theme.colors.brand.black.hex()};
`

export const HistoryEntryContainer = styled.div`
  margin-top: 48px;
`

export const LoadingContainer = styled.div`
  background: ${({ theme }) => theme.colors.brand.white.hex()};
  align-items: center;
  display: flex;
  justify-content: center;

  background: ${({ theme }) => theme.colors.brand.white.hex()};

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  z-index: 1;
`
