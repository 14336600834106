import React from 'react'

import { AddressCard } from '../../../../../components/AddressCard/AddressCard'
import { OrderHistoryEntryCard } from '../../../../../components/OrderHistoryEntryCard/OrderHistoryEntryCard'
import { useText } from '../../../../../content'
import { useUserHistory } from '../../../../../hooks/useUserHistory'
import { HistoryEntryContainer, SectionTitle } from '../DeliveryAddressResults.styles'

export const DeliveryAddresResultsEmptyState = React.memo(({ firebaseSavedAddresses, onStartOrder }) => {
  const { text } = useText()
  const historyInfo = useUserHistory()
  return (
    <>
      {firebaseSavedAddresses?.length > 0 && (
        <HistoryEntryContainer>
          <SectionTitle>{text('Profile.SavedAddresses.Title')}</SectionTitle>
          {firebaseSavedAddresses.map((address, idx) => (
            <AddressCard
              address={address}
              buttons={[{ variant: 'primary', label: text('Home.Buttons.StartOrder'), onClick: () => onStartOrder(address), isSmall: true }]}
              key={address.id}
              style={{ marginTop: idx > 0 ? 12 : 0 }}
            />
          ))}
        </HistoryEntryContainer>
      )}
      {historyInfo?.lastOrder && (
        <HistoryEntryContainer>
          <SectionTitle>{text('Home.Section.ReOrder.Title')}</SectionTitle>
          <OrderHistoryEntryCard restaurants={historyInfo.restaurants} {...historyInfo.lastOrder} />
        </HistoryEntryContainer>
      )}
    </>
  )
})
