import { useQuery } from '@apollo/client'
import { maxBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { FiChevronUp } from 'react-icons/fi'

import { HANDOFF_MODES, isSameHandoffMode } from '../../constants/handoffModes'
import { GET_MENU } from '../../data/menu/queries'
import { parseIngredients } from '../../data/utils'
import { useCreateBasketFromOrder } from '../../hooks/useCreateBasketFromOrder'
import { useUtensils } from '../../hooks/useUtensils'
import firebase from '../../services/firebase'
import { buildLastOrderAddressLine } from '../../utils/address'
import {
  AddToBagButton,
  ChevronContainer,
  DetailsButton,
  OrderCardContainer,
  OrderHeader,
  OrderMetaText,
  OrderTitle,
  Price,
  ProductListContainer,
  ProductMeta,
  ProductMetaContainer,
  ProductName,
  Row,
} from './OrderHistoryEntryCard.styles'

export const OrderHistoryEntryCard = ({ restaurants, style, ...order }) => {
  const [expanded, setExpanded] = useState(false)
  const { addToBag } = useCreateBasketFromOrder()

  const { data: menuItems } = useQuery(GET_MENU, {
    skip: !order?.vendorId,
    fetchPolicy: 'network-only',
    variables: { restaurantId: order?.vendorId },
  })

  const { includeUtensilModifierOption, excludeUtensilModifierOption, includeMultiUtensilsModifierOptions } = useUtensils({
    menuItems,
    products: order?.products,
  })

  const hiddenProductNames = useMemo(() => {
    if (includeMultiUtensilsModifierOptions?.length > 0) {
      return includeMultiUtensilsModifierOptions.map(option => option.name.toUpperCase())
    }
    return [includeUtensilModifierOption?.name, excludeUtensilModifierOption?.name].filter(Boolean).map(name => name.toUpperCase())
  }, [includeUtensilModifierOption, excludeUtensilModifierOption, includeMultiUtensilsModifierOptions])

  const orderProducts = useMemo(() => order?.products?.filter(p => !hiddenProductNames.find(name => name === p.name.toUpperCase())) || [], [
    order?.products,
    hiddenProductNames,
  ])

  const totalProductQuantity = useMemo(() => orderProducts.reduce((sum, cur) => sum + cur.quantity, 0), [orderProducts])
  const orderTitle = useMemo(() => maxBy(orderProducts, 'totalCost')?.name, [order])
  const restaurantAddress = useMemo(() => restaurants.find(rest => rest?.id === order.vendorId)?.streetAddress, [order.vendorId, restaurants])

  function handleAddToBag(order, restaurants) {
    firebase.analytics().logEvent('Profile_Order_History_Add_To_Bag', {
      orderId: order.id,
    })

    addToBag(order, restaurants)
  }

  return (
    <OrderCardContainer key={order.id} style={style}>
      <OrderHeader>
        <OrderTitle>
          {orderTitle} {totalProductQuantity > 1 && `(+${totalProductQuantity} items)`}
        </OrderTitle>
        <Price>$ {order?.total}</Price>
      </OrderHeader>

      <OrderMetaText style={{ marginTop: 4 }}>
        {isSameHandoffMode(order?.deliveryMode, HANDOFF_MODES.PICKUP)
          ? `Pickup at ${restaurantAddress}`
          : `Delivery to ${buildLastOrderAddressLine(order?.deliveryAddress)}`}
      </OrderMetaText>

      {expanded && (
        <ProductListContainer style={{ marginTop: 27 }}>
          {orderProducts.map(product => {
            const ingredients = parseIngredients(product)
            const subItems = ingredients?.length ? ingredients : product.choices || []

            return (
              <ProductMetaContainer key={product.name}>
                <OrderHeader>
                  <ProductName>
                    {product.name}
                    {product.quantity > 1 && ` (${product.quantity}x)`}
                  </ProductName>
                  <Price style={{ right: 0, top: 14 }}>$ {product.totalCost}</Price>
                </OrderHeader>
                <ProductMeta>{subItems.map(item => item.name).join(' • ')}</ProductMeta>
              </ProductMetaContainer>
            )
          })}
        </ProductListContainer>
      )}

      <Row style={{ marginTop: 27 }}>
        {expanded ? (
          <ChevronContainer onClick={() => setExpanded(false)}>
            <FiChevronUp />
          </ChevronContainer>
        ) : (
          <DetailsButton onClick={() => setExpanded(true)}>Details</DetailsButton>
        )}

        <AddToBagButton label="Add to Bag" onClick={() => handleAddToBag(order, restaurants)} />
      </Row>
    </OrderCardContainer>
  )
}
